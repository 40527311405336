import { mixpanelUserLogin, mixpanelEventTrack, mixpanelUserLogout } from './mixpanel-services';
import { webEngageUserLogin, webEngageEventTrack, webEngageUserLogout } from './webenage-services';


const trackServices = {
  mixpanel: mixpanelEventTrack,
  webengage: webEngageEventTrack,
  both: (event) => {
    mixpanelEventTrack(event);
    webEngageEventTrack(event);
  },
};



export const loginUser = (user) => {
  mixpanelUserLogin(user);
  webEngageUserLogin(user);
};

export const trackEvent = (event, service = 'both') => {
  const trackingFunction = trackServices[service] || trackServices['both'];
  trackingFunction(event);
};

export const logout = () => {
  mixpanelUserLogout();
  webEngageUserLogout();
};
