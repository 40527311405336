export const events = {
    "entity": {
      "user_lands_ypaypage": {
        "event_name": "Landing onto YPay Page",
        "attributes_user_lands_ypaypage": {
          "landing_on_ypaypage_key": "landing_on_ypaypage",
          
        }
      },
      "clicks_bookachatsession_ypaypage": {
        "event_name": "Clicks on Book a chat session",
        "attributes_clicks_bookachatsession_ypaypage": {
          "click_on_booking_key": "click_on_booking",
          
        }
      },
      "clicks_bookasession_ypaypage": {
        "event_name": "Clicks on Book a session",
        "attributes_clicks_bookasession_ypaypage": {
          "click_on_booking_key": "click_on_booking",
          
        }
      },
      "user_visits_sunshine": {
        "event_name": "Visits Sunshine Page",
        "attributes_user_visits_sunshine": {
          "visits_sunshine_key": "visits_sunshine",
          
        }
      },
      "user_viewed_lead_gen_form": {
        "event_name": "User Viewed Lead Generation Form",
        "attributes_user_viewed_lead_gen_form": {
          "viewed_lead_gen_form_key": "viewed_lead_gen_form",
          
        }
      },
      "user_clicked_on_book_session": {
        "event_name": "User Clicked On Book Session",
        "attributes_user_clicked_on_book_session": {
          "clicked_on_book_session_key": "clicked_on_book_session",
          
        }
      },
      "user_visits_education": {
        "event_name": "Visits Education Page",
        "attributes_user_visits_education": {
          "visits_education_key": "visits_education",
          
        }
      },
      "user_make_payment": {
        "event_name": "User Make Payment",
        "attributes_user_make_payment": {
          "make_payment_key": "make_payment_education",          
        }
      },
      
  }
}