import Cookies from 'universal-cookie';
const mainDomain = process.env.NEXT_PUBLIC_APP_ENV === 'DEV'? 'localhost' :'.lissun.app'; // Replace 'example.com' with your main domain

const cookies = new Cookies();

export const getAuth = () => {

  const auth = cookies.get('AUTH');

  return auth
}

export const setAuth = (authObject) => {

  cookies.set('AUTH', JSON.stringify(authObject), { path: '/',domain: mainDomain });
  return authObject;
}

export const removeAuth = () => {
  cookies.remove('AUTH', { path: '/', domain: mainDomain });
  return;
}


export const isInRole = (role, user) => {
  return user.roles && user.roles.includes(role);
}

export const isAuthenticated = (user, role) => {
  if (user != null && user.token && role) {
    if(!user.role) return false;

    return user.role && user.role.includes(role);
  }

  return user != null && user.token;
}


export const setUnregisteredUser=(authObject)=>{
  cookies.set('UNREGISAUTH', JSON.stringify(authObject), { path: '/' });
  return {authObject};
}

export const getUnregisteredUser = () => {
  const auth = cookies.get('UNREGISAUTH');
  return auth
}

