const userDetailKeys = {'phone':'mobile', 'email':'email', name: 'name'}

export const webEngageUserLogin = (user) => {
  if (webengage) {
    try {
        webengage.user.login(user.id);
        Object.keys(userDetailKeys).forEach(key => {
          if (user[key]) {
            webengage.user.setAttribute(key, user[key]);
          }
        });
        webengage.user.setAttribute('role', user['role'] || 'User')
    } catch (err) {
      console.error('Error logging in user with WebEngage:', err);
    }
  }
};

export const webEngageEventTrack = (event) => {
  // if (webengage) {
    try {
      webengage.track(Object.keys(event)[0], Object.values(event)[0]);
    } catch (err) {
      console.error('Error tracking event with WebEngage:', err);
    }
  // }
};

export const webEngageUserLogout = () => {
    if (webengage) {
      try {
        webengage.logout();
      } catch (err) {
        console.error('Error logging out user with WebEngage:', err);
      }
    }
  };

