const userDetailKeys = {'phone':'mobile', 'email':'email', name: 'name'}


export const mixpanelUserLogin = (user) => {
  if (mixpanel) {
    try {
        mixpanel.identify(user.id);
        Object.keys(userDetailKeys).forEach(key => {
          if (user[key]) {
              mixpanel.register({key: user[key]});
          }
        });
        mixpanel.register({'role': user['role'] || 'User'})

    } catch (err) {
      console.error('Error identifying user with Mixpanel:', err);
    }
  }
};

export const mixpanelEventTrack = (event) => {
  // if (mixpanel) {
    try {
      mixpanel.track(Object.keys(event)[0], Object.values(event)[0]);
    } catch (err) {
        console.error('Error tracking event with Mixpanel:', err);
    }
  // }
};

export const mixpanelUserLogout = () => {
    if (mixpanel) {
      try {
        mixpanel.reset();
      } catch (err) {
        console.error('Error logging out user with Mixpanel:', err);
      }
    }
  };