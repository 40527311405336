import axios from "axios";
import requestedFrom from "../utils/detect-device";

import { getUnregisteredUser } from "./identity.service";

const isBrowser = () => typeof window !== "undefined";

export const postWithOutAuth = (url, entity) => {
    const headers = {
        "content-type": "application/json",
        "x-platform": (process.env.NEXT_PUBLIC_APP_ENV || "no-env") + "Web",
        "x-requestedFrom": isBrowser() ? requestedFrom() : "WINDOW_WEB",
    };
    return new Promise((resolve, reject) => {
        axios
            .post(url, entity, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve(response.data);
                }
            })
            .catch((ex) => {
                reject(ex);
            });
    });
};

export const postWithAuth = (url, entity) => {
    return new Promise((resolve, reject) => {
        const auth = getUnregisteredUser();

        const headers = {
            "content-type": "application/json",
            "x-access-token": auth?.token,
            "x-requestedFrom": isBrowser() ? requestedFrom() : "WINDOW_WEB",
            "x-platform": (process.env.NEXT_PUBLIC_APP_ENV || "no-env") + "Web",
        };
        axios
            .post(url, entity, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve(response.data);
                }
            })
            .catch((ex) => {
                reject(ex);
            });
    });
};

export const getWithAuth = (url, token = "") => {
    return new Promise((resolve, reject) => {
        const headers = {
            "content-type": "application/json",
            "x-access-token": token,
            "x-platform": (process.env.NEXT_PUBLIC_APP_ENV || "no-env") + "Web",
            "x-requestedFrom": isBrowser() ? requestedFrom() : "WINDOW_WEB",
        };
        axios
            .get(url, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve(response.data);
                }
            })
            .catch((ex) => {
                reject(ex);
            });
    });
};

export const getWithAuthToken = (url) => {
    return new Promise(async (resolve, reject) => {
        const auth = getUnregisteredUser();
        const headers = {
            "content-type": "application/json",
            "x-access-token": auth?.token,
            "x-platform": (process.env.NEXT_PUBLIC_APP_ENV || "no-env") + "Web",
            "x-requestedFrom": isBrowser() ? requestedFrom() : "WINDOW_WEB",
        };
        axios
            .get(url, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve(response.data);
                }
            })
            .catch((ex) => {
                reject(ex);
            });
    });
};

export const deleteWithAuth = (url, entity) => {
    const auth = getUnregisteredUser();
    const headers = {
        "content-type": "application/json",
        "x-access-token": auth.token,
        "x-platform": (process.env.NEXT_PUBLIC_APP_ENV || "no-env") + "Web",
        "x-requestedFrom": isBrowser() ? requestedFrom() : "WINDOW_WEB",
    };
    return new Promise((resolve, reject) => {
        axios
            .delete(url, {
                data: entity,
                headers,
            })
            .then((response) => {
                if (response && response.data) {
                    resolve(response.data);
                }
            })
            .catch((ex) => {
                reject(ex);
            });
    });
};

export const putWithAuth = (url, entity, token = "") => {
    const auth = getUnregisteredUser();
    return new Promise((resolve, reject) => {
        const headers = {
            "content-type": "application/json",
            "x-access-token": auth?.token,
            "x-platform": (process.env.NEXT_PUBLIC_APP_ENV || "no-env") + "Web",
            "x-requestedFrom": isBrowser() ? requestedFrom() : "WINDOW_WEB",
        };
        axios
            .put(url, entity, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve(response.data);
                }
            })
            .catch((ex) => {
                reject(ex);
            });
    });
};

export const putWithOutAuth = (url, entity) => {
    const headers = {
        "x-requestedFrom": isBrowser() ? requestedFrom() : "WINDOW_WEB",
        "content-type": "application/json",
    };

    return new Promise((resolve, _reject) => {
        axios
            .put(url, entity, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve({ status: true, data: response.data });
                }
            })
            .catch((ex) => {
                resolve({ status: false, message: ex.message });
            });
    });
};

export const postWithAuthToken = (url, entity, token = "") => {
    return new Promise((resolve, reject) => {
        const headers = {
            "content-type": "application/json",
            "x-access-token": token,
            "x-requestedFrom": isBrowser() ? requestedFrom() : "WINDOW_WEB",
            "x-platform": (process.env.NEXT_PUBLIC_APP_ENV || "no-env") + "Web",
        };
        axios
            .post(url, entity, { headers })
            .then((response) => {
                if (response && response.data) {
                    resolve(response.data);
                }
            })
            .catch((ex) => {
                reject(ex);
            });
    });
};
