const ApiUrl = process.env.NEXT_PUBLIC_API_URL;

const UrlParamsReplace = (url, params = {}) => {
	let urlWithPrefix = `${ApiUrl}${url}`;
	if (params) {
		Object.keys(params).forEach(
			(key) => (urlWithPrefix = urlWithPrefix.replace(`:${key}`, params[key]))
		);
	}
	return urlWithPrefix;
};

export const GET_MAPPED_ASSESSMENT = (brandId) => UrlParamsReplace(`/mapped-assessments?brandId=:brandId`, { brandId })
export const CREATE_UNREGISTERED_USER_ASSESSMENTS = () => UrlParamsReplace('/unregistered-user-assessments');
export const CREATE_UNREGISTERED_USER_RESPONSES = (unregisteredUserId) => UrlParamsReplace('/unregistered-user-responses/:unregisteredUserId', {
	unregisteredUserId
})
export const GET_BRAND_ASSESSMENT_URL = (brandAssessmentId) => UrlParamsReplace('/brand-assessments/:brandAssessmentId', { brandAssessmentId });

export const CREATE_BRAND_USER_URL = () => UrlParamsReplace('/brand-users')
export const CREATE_UPDATE_QUESTION_RESPONSE_URL = UrlParamsReplace('/add-or-update-question-response')
export const GET_QUESTIONNAIRE_RESPONSE_URL = (questionnaireId, brandId) => UrlParamsReplace('/get-questionnaire-response/:questionnaireId/:brandId', { questionnaireId, brandId })

export const CREATE_UNREGISTERED_USER_ASSESSMENT_RESPONSES = (unregisteredUserId, assessmentId) => UrlParamsReplace('/unregistered-user-assessment-responses/:unregisteredUserId?assessmentId=:assessmentId',
	{ unregisteredUserId, assessmentId })

export const GET_BRAND_USER_URL = (brandId, logginOption, identifier) => UrlParamsReplace('/brand-student-info?brandId=:brandId&logginOption=:logginOption&identifier=:identifier', { brandId, logginOption, identifier });
export const GET_GODIGIT_PACKAGE_URL = () => UrlParamsReplace('/godigit/packages');
export const GET_REF_USER_TOKEN = (id) => UrlParamsReplace('/ref-user-token/:id', { id })

export const CREATE_SEND_MESSAGE = () => UrlParamsReplace('/send-message');

export const GET_CONTENT_TAG_SHOW_IN_NAVIGATION = (isContentTag) => UrlParamsReplace('/get-all-tag-show-in-navigation?isContentTag=:isContentTag', { isContentTag })


export const CREATE_Y_PAY_COUPON_URL =  UrlParamsReplace('/ypay-update-ref-user-coupon');
export const GET_Y_PAY_COUPON_DETAILS_URL = () => UrlParamsReplace('/ypay-get-coupons-details');
export const GET_NEWS_ORDERINGS_URL = (contentFor, skip, limit) => UrlParamsReplace('/news-orderings?skip=:skip&limit=:limit&contentFor=:contentFor', { skip, limit, contentFor })
export const CREATE_SEND_CONTACT_MESSAGE_FOR_EDUCATION = () => UrlParamsReplace('/send-contact-message-for-education');

export const GET_THERAPIST_BY_FILTER = UrlParamsReplace('/filter/therapist');
export const CREATE_UPDATE_STUDENT_DETAILS_FOR_EDUCATION = () => UrlParamsReplace('/edu-student');
export const CREATE_EDU_STUDENT_PAYMENT_ORDER_ID = UrlParamsReplace('/create-raz-pay-order-for-edu-student')
export const GET_PAYMENT_ORDER = (paymentOrderId) => UrlParamsReplace('/get-payment-order/:paymentOrderId', { paymentOrderId });
export const SEND_OTP_TO_SUNSHINE_USER = () => UrlParamsReplace('/send-otp-to-sunshine-user');
export const VERIFY_OTP_TO_SUNSHINE_USER = () => UrlParamsReplace('/verify-otp-sunshine-user');
export const CREATE_YPAY_LEAD = () => UrlParamsReplace('/ypay-lead');
export const CREATE_SUNSHINE_CENTER_RESPONSE=()=>UrlParamsReplace('/sunshine-visit-center')
export const GET_CONTENT_BY_ID_OR_SLUG_URL = (id, slug) => UrlParamsReplace('/contents/:id?slug=:slug', { id, slug })
